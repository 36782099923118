.c-faq-item{
    margin-bottom: 4rem;

    @include respond(600){
        padding: 0rem 3rem;
    }
    @include respond(365){
        padding: 0rem 2rem;
    }
    &__header{
        display: flex;
        gap: 2rem;
        align-items: center;
        margin-left: -1rem;
         @include respond(450){
            gap: 1rem;
            
        }
        @include respond(365){
            flex-direction: column;
            align-items: flex-start;
            margin: 0;
        }
    }
    &__body{
        line-height: 25px;
        color: #58585A;
        margin-top: 1rem;
        @include respond(450){
            font-size: 20px;
        }
        @include respond(450){
            line-height: 23px;
            font-size: 15px;
        }
    }
    &__title{
        margin: 0;
        font-size: 22px;
        font-weight: 600;
        color: var(--primary-color);
        @include respond(450){
            font-size: 20px;
        }
    }
    &__icon{
        width: 7rem;
        @include respond(450){
            width: 5rem;
        }
    }
}