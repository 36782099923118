$carousel-arrow-color: white !default;
$carousel-prev-character: "\2190" !default;
$carousel-next-character: "\2192" !default;
$carousel-opacity-not-active: 0.25 !default;
$carousel-opacity-default: 0.75 !default;
$carousel-opacity-on-hover: 1 !default;

.c-carousel-hero{

    & .carousel-caption{
        right: initial;
        left: 8%;
        bottom: initial;
        top: 20%;

        @include respond(1500){
            left: 11%;
        }
    }
    & .carousel-inner{
        height: 100vh;
    }
    & .carousel-item,
    & .carousel-item img{
        height: 100%;
    }
    & .carousel-indicators{
        margin: 0;
        left: 8%;
        right: initial;
        margin-bottom: 2rem;
        @include respond(1500){
            left: 11%;
        }
    }
}



/* Arrows */

.carousel-control-prev,
.carousel-control-next {
    position: absolute;
    font-size: 0px;
    cursor: pointer;
    transform: translate(0, -50%);
    color: #fff;
    cursor: pointer;
    height: 4rem;
    width: 4rem;
    border: 0;
    background: #788A98;
    transition: all .2s;
    border-radius: 5px;

    &.carousel-control-disabled:before {
        opacity: $carousel-opacity-not-active;
    }
    &:before {
        font-size: 20px;
        line-height: 1;
        color: $carousel-opacity-not-active;
        opacity: $carousel-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    &.active{
        background-color: var(--secondary-color);
    }
}

.carousel-control-prev {
    [dir="rtl"] & {
        left: auto;
        right: -25px;
    }
    &:before {
        content: $carousel-prev-character;
        [dir="rtl"] & {
            content: $carousel-next-character;
        }
    }
}

.carousel-control-next {
    [dir="rtl"] & {
        left: -25px;
        right: auto;
    }
    &:before {
        content: $carousel-next-character;
        [dir="rtl"] & {
            content: $carousel-prev-character;
        }
    }
}

.welcolme-carousel{
    padding: 60px;
    & .carousel-item,
    & .carousel-item img{
        height: auto;
    }
    & .carousel-indicators{
        margin: 0;
        left: 8%;
        right: initial;
        margin-bottom: 2rem;
        .active{
            background-color: var(--secondary-color);
        }
        @include respond(1500){
            left: 11%;
        }
        @include respond(768){
            display: none;
        }
    }
    & .carousel-controls{
        & span{
            display: none;
            font-size: 26px;
            color: #fff;
            @include respond(768){
                display: block;
            }
        }
        @include respond(768){
            display: flex;
            margin-top: 2rem;
            align-items: center;
            justify-content: space-between;
        }
    }
    & .carousel-control-prev{
        left: 1rem;
    }
    & .carousel-control-next{
        right: 1rem;
    }
    & .carousel-control-prev, 
    & .carousel-control-next{
        border-radius: 50%;
        top: 50%;
        opacity: unset !important;
        @include respond(768){
            transform: none;
            position: relative;
            width: 6rem;
            height: 6rem;
        }
    }
    @include respond(768){
        padding: 0px;
    }
}

.owl-carousel { margin:10px auto; width:400px; max-width:95%; }
.owl-carousel .owl-stage {
    max-height: 200px;
    display: flex;
    align-items: center;
}.carousel_2 .current { border:2px solid #639; box-sizing:border-box }
.carousel_2 .current img { transform-style:flat!important }

.carousel_2 .active.center + .active,.carousel_2 .active:first-child,.carousel_2 :not(.active) + .active{
  height:150px;
}
.carousel_2 .active.center{
  height:200px;
}
.owl-carousel .owl-item img{height:100%}