.c-modal-info{
    & .modal-content{
        width: 48rem;
        border-radius: 1.5rem;
        overflow: hidden;
    }
    &__header{
        padding: 3rem;
        text-align: center;
        background: #24515e;
    }
    &__title{
        margin: 0;
        font-size: 16px;
        font-weight: 600;
        color: white;
    }
    & .modal-body{
        padding: 2rem;
        font-weight: 500;
        text-align: justify;
        
        @include respond(450){
            padding: 3rem;
        }

        & p{
            margin-bottom: 1.5rem;
        }
    }
    &__footer{
        padding: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.c-modal-welcome{
    background: rgba(0, 0, 0, 0.6);
    & .modal-dialog{
        max-width: 600px;
    }
    & .modal-content{
        border: none !important;
        background: transparent !important;
        & .c-modal-welcome__button{
            top: 5.5rem;
            right: 5rem;
            @include respond(768){
                top: 0rem;
                right: 0rem;
            }
        }
    }
    &__button{
        position: absolute;
        top: -1rem;
        background: var(--secondary-color);
        right: -1rem;
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        @include respond(768){
            width: 4rem;
            height: 4rem;
        }
        & button{
            font-size: 12px;
            padding: 0;
            font-size: 9px;
            padding: 0;
            width: 100%;
            height: 100%;
        }
    }

    & video{
        width: 50vw;
        margin-left: -6rem;
        clip-path: inset(1px 1px);

        @include respond(768){
            width: 60vw;
        }
    }
}

.c-modal-success{

    & .modal-dialog{
        width: 48rem;
       
        @include respond(600){
            width: initial;
        }
    }
    & .modal-content{
        height: 55rem;
        background: var(--primary-color);
        border-radius: 1.5rem;
        @include respond(365){
           height: 90vh;
        }
    }
    & .modal-body{
        display: flex;
        flex-direction: column;
        color: white;
        text-align: center;
        gap: 1rem;
        padding: 10rem 5rem 5rem 5rem;

        @include respond(365){
            padding: 3rem;
        }
    }
    &__title{
        font-size: 27px;
        margin-top: 2rem;
        
    }
    &__text{
        font-size: 15px;
        margin: 0 auto;
        max-width: 25rem;
    }
    &__footer{
        margin: auto auto 0 auto;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        max-width: 25rem;
    }
    & .c-btn{
        min-width: 25rem;
    }
}