.c-timeline{
    display: flex;
    gap: 6rem;
    width: auto;
    overflow-x: auto;
    scroll-behavior: smooth;
    &::-webkit-scrollbar{
        display: none;
    }
    &__item{
        min-width: 40rem;
        @include respond(450){
            min-width: 85vw;
         }

        &:last-child{
            margin-right: 5rem;
           & .c-timeline__icon::before{
                display: none;
           }
        }
        &--small{
            min-width: 25rem;
        }
    }
    &__year{
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 1rem;
    }
    &__title{
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 0.5rem;
    }
    &__text{
        color: #666666;
    }
    &__icon{
        margin-bottom: 4rem;
        position: relative;

        & img{
            margin-left: -1rem;
            width: 6rem;
        }

        &::before{
            content: '';
            position: absolute;
            background: transparent;
            width: calc(100% + 6rem);
            height: 1rem;
            top: 2rem;
            z-index: -1;
            background-image: linear-gradient(to right, #58585A 55%, rgba(255,255,255,0) 20%);
            background-position: bottom;
            background-size: 14px 1px;
            background-repeat: repeat-x;
        }
    }
    &__button{
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        border: 0;
        background: white;
        color: var(--primary-color);
        box-shadow: 1px 1px 6px #24515e4d;
        transition: all .2s;
       
        
        &.btn-right{
            background-color: var(--secondary-color);
            color: white;
        }
        &:hover{
            transform: scale(1.08);
            background-color: var(--primary-color);
            color: white;
        }
        &:active{
            transform: scale(.9);
        }
    }
}


.c-timeline-year{

    &__header{
        display: flex;
    gap: 5rem;
    justify-content: center;
    align-items: center;
        position: relative;
        padding: 0 4rem;
        height: 220px;
        overflow-x: hidden;
        @include respond(600){
            padding: 0 10rem;
            margin: 6rem 0 10rem;
            margin-bottom: 1rem;
            gap: 3rem;
        }
    &::before{
        content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    border-top: 1px dashed #B1B1B1;
    top: 66%;
    z-index: -1;
    }
    }
    &__year{
        display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
        position: relative;
        transition: all .3s;
        margin-right: 0;
        
    &.is-active{
        transform: scale(1.8);
        top: -2rem;
        margin-right: auto;
    }
   

    & span{
        font-size: var(--big-size-2);
        font-weight: 600;
        color: #707070;
        
    }
    & div{
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border-radius: 50%;
        width: 7rem;
        height: 7rem;
        display: flex;
        justify-content: center;
        align-items: center; 
        background-color: white;
        @include respond(600){
            width: 5rem;
        height: 5rem;
        }

        & img{
            width: 3rem;
            position: relative;
            top: -2px;
            @include respond(600){
                width: 2rem;
            }
        }
    }
    }

    &__body{
        display: flex;
        overflow: hidden;
        width: 100rem;
        margin: 0 auto;
        height: 46rem;
        @include respond(768){
            height: 60rem;
        }
        @include respond(1200){
            position: relative;
            width: 100%;
        }
        @include respond(600){
           height: 100vh;
           max-height: 60rem;
        }
    }

    &__card{
        display: flex;
        
        position: absolute;
        opacity: 0;
        width: 100rem;
        flex: 0 0 100%;
        transition: opacity 0.5s ease-in-out;
        @include respond(1200){
            left: 2rem;
            right: 2rem;
            width: 100%;
        }
        @include respond(768){
            flex-direction: column;
            left: 0rem;
            right: 0rem;
        }
        &.is-active{
            opacity: 1;
            z-index: 1;
        }

    &__image{
        width: clamp(40rem, 100%, 46rem);
    height: 46rem;
    border-radius: 18px;
    overflow: hidden;
    & img{
        width: 100%;
        object-fit: cover;
        height: 100%;
    }
    @include respond(768){
        width: clamp(100%,100%,100%);
        height: 28rem;
    }
    }
    &__content{
        flex: 1;
        padding: 4rem 4rem 4rem 9rem;
        max-height: 31rem;
        overflow-y: scroll;
        @include respond(1200){
            min-width: 44rem;
            padding: 4rem 6rem 4rem 5rem;
        }
        @include respond(768){
            padding: 3rem 2rem;
            min-width: initial;
        }
        @include respond(450){
           padding: 2rem 0rem;
         }
        & h4{
            font-size: var(--big-size-2);
            margin-bottom: 3rem;

            @include respond(450){
                margin-bottom: 2rem;
             }
        }
        & p{
            @include respond(450){
                max-height: 50vh;
                overflow: auto;
                margin: 0;
                max-height: 30rem;
             }
        }
    }
    }

    &__footer{
        display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    margin-left: 22rem;
    position: relative;
    
    @include respond(1200){
        top: 0rem;
    }
    @include respond(600){
        margin-left: auto;
     }
    @include respond(450){
        top: 3rem;
     }
     
    }
}