.c-header{
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    gap: 1rem;
    padding: 2rem 10rem;
    align-items: center;
    z-index: 3;


    @include respond(600){
       padding: 2rem;
     }
    @include respond(1500){
        padding: 2rem 20rem;
    }

    &__logo{
        width: 15rem;
        & img{
            width: 100%;
        }
    }
    &__nav{
        margin: 0;
        padding: 0;
        list-style-type: none;
        display: flex;
        gap: 3rem;
        font-size: 15px;
        margin-left: auto;
        align-items: baseline;
        @include respond(1065){
            display: none;
        }
        & .dropdown-menu{
            font-size: 14px;
            border-radius: 1.5rem;
            border: 0;
            padding: 2rem 2rem;

            .dropdown-item{
                margin-bottom: 3px;
                color: var(--primary-color);
                border-radius: 5px;
                &:active{
                    background-color: white;
                    color: var(--secondary-color);
                }
                p{
                    margin: 0 !important;
                }
            }
        }
        & .c-header__buttons{
            display: flex;
            gap: 1rem;
            & a{
                padding: 1rem 0.75rem!important;
                font-size: 10px;
            }
        }
    }
    &__item{
        color: white;
        cursor: pointer;
        text-decoration: none;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;

        & span{
            font-size: 12px;
        }
        
        &:hover{
            opacity: .45;
            color: white;
        }
    }
    &__button{
        border: 0;
        border-radius: 5px;
        background: transparent;
        height: 4rem;
        width: 4rem;
        display: flex;
        justify-content: center;
        align-items: center;
        @include respond(1065){
           margin-left: auto;
        }
        &:hover{
            background-color: var(--secondary-color);
        }
    }
    &--white{
        background-color: white;
        position: initial;
        & .c-header__nav .c-header__item,
        & .c-header__nav .c-header__item span:before{
            color: var(--primary-color);
        }
        & .c-header__nav .dropdown-menu{
            box-shadow: 1px 1px 8px 0px #24515e36;
        }
        & .c-header__nav .c-btn--outline{
            border-color: #24515e5c;
            color: #24515eed;
            &:hover{
                background-color: var(--secondary-color);
                color: white;
                border-color: var(--secondary-color);
            }
        }
        & .c-header__button {
            &:hover{
                & span::before{
                    color: white;
                }
            }
            & span::before{
                color: var(--primary-color);
            }
        }
    }
}


/* ////////////////////////////////////* /
/*              SIDEBAR                */
/* /////////////////////////////////// */
.c-header-sidebar{
    background: var(--primary-color);
    position: fixed;
    top: 0;
    right: -40rem;
    bottom: 0;
    padding: 4rem 0rem;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    width: 35rem;
    transition: all ease-out .3s;
    z-index: 1;

    &.is-open{
        right: 0px;
    }
    &__body{
        overflow-y: auto;
        flex: 1;
    }
    &__nav{
        padding: 0;
        margin: 0;
        list-style-type: none;

        & a{
            margin-bottom: 1rem;
            justify-content: flex-end;
            padding: 1rem 6rem;

        }
    }
    & .dropdown-menu{
        transform: translate(0px, 50px) !important;
        position: absolute !important;
        background: #15404d;
        border: 0;
        padding: 0;
        width: 100%;

         & li:last-child a{
            border-bottom: none;
        }
    }
    & .dropdown-item{
        margin: 0 !important;
        color: white;
        font-size: 13px;
        padding: 0.8rem 3rem;
        border-bottom: 1px solid #24515E;
        white-space: initial;
        
        &:hover,
        &:focus{
            background-color: #10343e;
        }
    }
    &__footer{
        text-align: center;
        color: white;
        margin-top: auto;
        border-top: 1px solid #4F737E;
        padding-top: 2rem;
        font-size: 13px;
    }

    & .btn-close{
        position: absolute;
        top: 2rem;
        left: 2rem;
    }

    &__bottom{
        display: none;
        justify-content: center;
        gap: 1rem;
        margin-top: 10%;
        & a{
            padding: 1rem 0.75rem!important;
            font-size: 10px;
        }
        @include respond(1065){
            display: flex;
            top: calc(100% - 20rem);;
            left: 0;
            margin: 2rem;
            width: calc(100% - 4rem);
        }
    }
}