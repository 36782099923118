/* ////////////////////////////////////* /
/*             CARD FUNDS               */
/* /////////////////////////////////// */
.c-card-funding{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--primary-color);
    border-radius: 1rem;
    max-width: 25rem;
    padding: 5rem 3rem 2rem 3rem;
    color: #fff;
    transition: all .2s;
    text-decoration: none;
    height: 26rem;

    @include respond(450){
        max-width: 100%;
        width: 100%;
    }

    &:hover{
        background-color: white;
        color: var(--primary-color);
        box-shadow: 0px 3px 26px rgba(0, 0, 0, 0.16);
    }
    & img{
        max-width: 9rem;
        object-fit: cover;
        height: 9rem;
    }
    &__text{
        text-decoration: none;
        text-align: left;
        margin-top: auto;
        font-size: 14px;
        font-weight: 700;
        
    }
}

/* ////////////////////////////////////* /
/*             CARD NEWS               */
/* /////////////////////////////////// */
.c-card-news{
    width: 30rem !important;
    background: var(--primary-color);
    padding: 2rem;
    border-radius: 2rem;
    color: white;

    &__image{
        border-radius: 2rem;
        overflow: hidden;
        height: 19rem;
        position: relative;
        img{
            width: 100%;
            object-fit: cover;
        }
    }
    &__title{
        margin: 2rem 0rem;
        font-weight: 600;
        font-size: 15px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    &__text{
        font-size: 14px;
        font-size: 14px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        height: 6rem;
    }
    &__date{
        position: absolute;
        bottom: -0.3rem;
        font-size: 14px;
        width: 100%;
        text-align: right;
        padding: 1rem 2rem;
        background: linear-gradient(360deg, #000000bd, transparent);
    }
}

/* ////////////////////////////////////* /
/*             CARD GREEN               */
/* /////////////////////////////////// */
.c-card-green{
    display: flex;
    background: linear-gradient(#36953e 0%, #7ab53f 100%);
    padding: 3rem 10rem;
    border-radius: 1rem;
    gap: 3rem;
    flex-wrap: wrap; 
    justify-content: center;
    @include respond(768){
        padding: 3rem;
    }
    @include respond(600){
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    &__item{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        @include respond(600){
            flex-direction: column;
        }
    }
    &__icon img{
        @include respond(450){
            width: 8rem;
         }
    }
    &__content{
        color: white;
        max-width: 25rem;

        @include respond(600){
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
        }

        div:nth-child(1){
            font-size: 20px;
            @include respond(450){
                font-size: 16px;
             }
        }
        div:nth-child(2){
            font-size: 55px;
            line-height: 55px;
            font-weight: 500;
            display: flex;
            align-items: baseline;
            gap: 1rem;
            @include respond(600){
                flex-direction: column;
                align-items: center;
                gap: 0;
            }
            @include respond(450){
                font-size: 40px;
                line-height: 1;
             }

            & span{
                font-size: 16px;
                font-weight: 400;
                line-height: 1;
                @include respond(600){
                    margin-bottom: 1rem;
                }
            }
        }
        div:nth-child(3){
            line-height: 19px;
        }
    }
}


/* ////////////////////////////////////* /
/*             CARD INFO               */
/* /////////////////////////////////// */
.c-card-info {
    display: flex;
    flex-direction: column;
    width: 35rem;
    padding: 2rem;
    border-radius: 1rem;
    transition: all .2s;

    @include respond(450){
        width: 100%;
        padding: 0rem;
        margin-bottom: 2rem;
    }

    &:hover{
        box-shadow: -1px 3px 9px 0px #24515e38;
        
    }

    &__image{
        position: relative;
        height: 30rem;
        margin-bottom: 2rem;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 1rem;
            transition: all .9s;
        }
    }
    &__icon{
        position: absolute;
        bottom: -3rem;
        right: -3rem;
        @include respond(365){
            bottom: -2rem;
            right: -2rem;
            width: 6rem;
        }
    }
    &__title{
        font-size: 16px;
        font-weight: 600;
        height: 4rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    &__text{
        font-size: 15px;
        margin-bottom: 2rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        height: 5rem;
    }
    &__link{
        font-size: 14px;
        font-weight: 500;
    }
}

/* ////////////////////////////////////* /
/*            CARD POWERBI              */
/* /////////////////////////////////// */
.c-card-powerbi{
    width: 40rem;
    border-radius: 2rem;
    background: var(--primary-color);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 12rem 5rem 6rem 5rem;

    @include respond(450){
        width: 100%;
    }

    &__text{
        color: white;
        text-align: center;
        font-weight: 600;
        font-size: 17px;
        margin-top: 4rem;
        max-width: 20rem;
    }
}
/* ////////////////////////////////////* /
/*            CARD HERO                */
/* /////////////////////////////////// */
.c-card-hero{
    border: 1px solid white;
    padding: 2rem;
    height: 20rem;
    border-radius: 1.3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    max-width: 17rem;

    @include respond(768){
        max-width: initial;
        flex: 1;
    }
    &__text{
        font-size: 11px;
        text-align: center;
    }

    & div:nth-child(2){
        font-size: 30px;
        display: flex;
        gap: 5px;
        align-items: center;
        font-weight: 500;

        & span{
            font-size: 16px;
        }
    }
}

/* ////////////////////////////////////* /
/*            CARD DATA                */
/* /////////////////////////////////// */
.c-card-data {
    background: #f6f6f6;
    padding: 4rem 8rem;
    border-radius: 2.5rem;

    @include respond(450){
        padding: 4rem 3rem;
    }

    &__item{
        margin-bottom: 1.5rem;
    }
    &__name{
        font-weight: 600;
    }
}