.c-btn{
    background: transparent;
    border: 1px solid transparent;
    font-size: 14px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 1rem 4rem;
    border-radius: 5px;
    transition: all .2s;
    text-decoration: none;
    text-align: center;
    display: inline-block;
    &--primary{
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
        color: white;
        &:hover{
            background-color: var(--secondary-color-darker);
            color: white;
        }
    }
    &--secondary{
        background-color: var(--primary-color);
        border-color: var(--primary-color);
        color: white;
        &:hover{
            background-color: #15323a;
            color: white;
        }
    }
    &--outline{
        background-color: transparent;
        color: white;
        border-color: white;
        &:hover{
            background-color: #ffffff2e;
            color: white;
        }
    }
    &--outline-dark{
        background-color: transparent;
        color: var(--primary-color);
        border-color: var(--primary-color);
        &:hover{
            background-color: var(--primary-color);
            color: white;
        }
    }
    &--white{
        background-color: white;
        color: var(--primary-color);
        border-color: white;
        &:hover{
            background-color: var(--secondary-color);
            color: white;
            border-color: var(--secondary-color);
        }
    }
}

.c-btn-scrollUp {
    position: fixed;
    bottom: 2rem;
    right: 3rem;
    z-index: 2;
    border: none;
    background-color: white;
    box-shadow: 1px 1px 5px 2px #222f3824;
    color: var(--secondary-color);
    cursor: pointer;
    width: 6rem;
    height: 6rem;
    padding: 15px;
    border-radius: 50%;
    font-size: 3rem;
    transition: all .2s;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
        background-color: var(--secondary-color);

        & span::before {
            color: white;
        }
    }

    &:active {

        transform: scale(.9);
    }

    &.js-show {
        right: 3rem;
    }

}

.c-btn-scrollDown{
    position: absolute;
    bottom: 0;
    right: 10%;
    padding: 2rem;
    border: 0;
    border-radius: 2.5rem 2.5rem 0 0;
    font-size: 40px;
    transition: all .2s;
    background-color: white;
    color: transparent !important;

    @include respond(450){
        right: 5%;
        font-size: 30px;
        padding: 1rem;
    }
    & span::before{
        color: var(--secondary-color);
    }
    &:hover{
        & span::before{
            color: var(--primary-color);
        }
    }
    &--green{
        background-color: var(--secondary-color);
        & span::before{
            color: white;
        }
    }
}