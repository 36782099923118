.l-complaint {
    background: #F4F4F4;
    padding: 4rem 10rem;
    padding-bottom: 6rem;
    @include respond(1500){
        padding: 4rem 20rem;
    }
    @include respond(768){
        padding: 3rem;
    }

    &__header{
        margin-bottom: 2rem;
    }
    &__body{
        & .row{
            & .col-lg-6:nth-child(1){
                padding-right: 6rem;
                @include respond(1000){
                    padding-right: 0rem;
                }
            }
            & .col-lg-6:nth-child(2){
                margin-top: -6rem;

                @include respond(1000){
                    margin-top: 3rem;
                }
            }
        }
    }
}