.l-news{
    background: #F4F4F4;
    padding: 4rem 10rem;
    @include respond(1200){
        padding: 3rem 3rem;
    }

    &__header{
        margin-bottom: 4rem;
        margin-left: -3.3rem;
        @include respond(1200){
            margin-left: 0rem;
        }


        & a{
            border: 0;
            padding: 1rem;
            color: transparent;
            background: #24515e0f;
            border-radius: 5px;
            transition: all .2s;
            &:hover{
                background-color: var(--secondary-color);
                & span::before{
                    color: white;
                }
            }
        }

    }
    &__title{
        font-size: 28px;
        display: flex;
        align-items: center;
        gap: 2rem;
        font-weight: 600;

        & span{
            font-size: 18px;
        }
    }
    &__subtitle{
        margin-left: 3.5rem;
    }
    &__grid{
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
        max-width: 103rem;
        margin: 0 auto;
        margin-top: 3rem;
        margin-bottom: 5rem;
        @include respond(1500){
            max-width: 138rem;
        }

        @include respond(1200){
            justify-content: center;
        }

        & .c-card-news{
            width: 33rem !important;
        }
    }
}

.l-news-detail{
    background: #F4F4F4;
    padding-top: 2rem;
    padding-bottom: 2rem;

    &__content{
        max-width: 85rem;
        margin: 0rem auto;
        background: white;
        border-radius: 2rem;
        padding: 4rem 6rem;
       
        @include respond(450){
            padding: 3rem;
        }
    }
    &__title{
        font-size: 28px;
        color: #000000;
        font-weight: 600;
        margin-top: 2rem;
        margin-bottom: 2rem;
        @include respond(450){
            font-size: 24px;
        }
    }
    &__image{
        border-radius: 2rem;
        overflow: hidden;
        position: relative;
        & img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    &__button{
        color: var(--primary-color);
        display: flex;
        align-items: center;
        gap: 1rem;
        text-decoration: none;
        transition: all .2s;

        &:hover{
            color: var(--secondary-color);
            & span::before{
                color: var(--secondary-color);
            }
        }
    }
    &__date{
        bottom: -0.3rem;
        font-size: 14px;
        width: 100%;
        text-align: right;
        padding: 2rem 2rem;
        background: linear-gradient(360deg, rgba(0, 0, 0, 0.7411764706), transparent);
        position: absolute;
        color: white;
    }
    &__body{
        margin: 4rem 0 2rem 0;
        max-height: 80rem;
        overflow-y: auto;
        padding-left: 3rem;
        direction: rtl;
        @include respond(450){
            padding-left: 2rem;
        }
        &::-webkit-scrollbar-track {
            background: #F4F4F4;
          }

        & p{
            direction: ltr;
            margin-bottom: 2rem;
            color: #58585A;
        }
    }
}