.c-tabs-goals{
    @include respond(600){
        display: flex;
        flex-direction: column-reverse;
    }
    & .tab-content{
        height: 50rem;
        @include respond(600){
            height: initial;
        }
    }
    &__content{
        flex: 1;
        flex-grow: 1;
        min-width: 50rem;
        @include respond(600){
            min-width: initial;
        }
    }
    &__title{
        font-size: 35px;
        font-weight: 600;
        margin-bottom: 5rem;
        @include respond(450){
            font-size: 30px;
            margin-bottom: 3rem;
        }
    }
    &__image{
        @include respond(600){
            display: none;
          }
    }
    &__item{
        display: flex;
        gap: 2rem;
        margin-bottom: 2rem;
        align-items: center;

        & img{
            width: 5rem;
            height: 5rem;
        }
    }
    &__text{
        margin: 0;
        font-size: 16px;
    }
    & .nav-tabs{
        border: none;
        @include respond(600){
            margin-bottom: 4rem;
            flex-direction: column;
        }

        & .nav-link {
            font-weight: 700;
            color: #64848D;
            border-bottom: 5px solid #64848D !important;
            padding: 0 2rem 1.5rem 0;
            margin: 0 5px;
            text-align: left;
            text-transform: uppercase;
            @include respond(600){
                border-left: 5px solid #64848D !important;
                border-bottom: none !important;
                padding: 1rem;
                margin-bottom: 1rem;

            }

            &:hover{
                color: white;
                border-color: transparent;
            }

            &.active{
                color: white;
                background: transparent;
                border-color: transparent;
                border-bottom: 5px solid var(--secondary-color) !important;

                @include respond(600){
                    border-left: 5px solid var(--secondary-color) !important;
                    border-bottom: none !important;
                }
            }
        }
    }
}


.c-tabs-funds{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__title{
        font-weight: 500;
        color: #58585A;
        margin-bottom: 3rem;
    }
    & .nav-pills{
        display: flex;
        gap: 1rem;
        margin-bottom: 3rem !important;

    }
    & .nav-item{
        @include respond(450){
            width: 100%;
         }
    }
    & .nav-link {
        background: #BCBDC0 !important;
        padding: 0rem;
        height: 5rem;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #58585A;
        font-weight: 600;
        width: 18rem;
        text-transform: uppercase;
        @include respond(450){
            width: 100%;
         }

        &.active{
            background: var(--primary-color) !important;
        }
    }

    &__iframe{
        height: 50rem;
        min-width: 80rem;
        overflow-y: hidden;
        display: block;
        width: 100%;
        overflow-x: auto;

        & iframe{
            width: 100%;
            height: 100%;
        }
    }
}


.c-tabs-faq{

    & .nav-tabs{
        border: 0;
        margin-bottom: 2rem;
    }
    & .nav-link{
        border: 0 !important;
        font-weight: 600;
        padding: 0;
        border-radius: 0 !important;
        color: #B5B5B5;
        

        &.active{
            color: var(--primary-color) !important;
            background: transparent;
        }

    &:nth-child(1){
        margin-right: 2rem;
        padding-right: 2rem;
        border-right: 1px solid #24515E !important;
    }
    }
}