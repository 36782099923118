.l-corporative{
    background: #F4F4F4;
    display: flex;

    @include respond(1000){
        flex-direction: column;
    }

    &__image{
        padding: 5rem;
        background: var(--primary-color);
        display: flex;
        align-items: center;
        flex: 1;
        justify-content: center;
        max-width: 50rem;
        @include respond(1000){
            max-width: initial;
            padding: 2rem;
        }
    

        & img{
           
            width: 100%;
            max-width: 25rem;
            @include respond(1000){
                max-width: 13rem;
            }
        }
    }
    &__paragraph{
        color: #58585A;
        max-width: 70rem;

        p{
            margin-bottom: 2rem;
        }

        span{
            color: #3621D8;
        }
        a{
            color: #3621D8;
            text-decoration: none;

            &:hover{
                text-decoration: underline;
            }
        }
    }
    &__content{
        padding: 5rem;
        padding-bottom: 10rem;
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include respond(1000){
            padding: 2rem;
        }
    
    }
}