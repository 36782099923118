.c-members{
    display: flex;
    flex-direction: column;
    gap: 4rem;
    max-width: 130rem;
    margin: 0 auto;

    

    &__item{
        display: flex;
        @include respond(600){
            flex-direction: column;
            gap: 3rem;
        }
    }
    &__icon{
        border-right: 1px solid #BCBDC0;
        margin-right: 4rem;
        padding-right: 4rem;
       
        & img{
            box-shadow: 0px 3px 26px rgb(0 0 0 / 16%);
            border-radius: 2rem;
        }
        @include respond(600){
            border-right: none;
        }
    }
    &__title{
        font-size: 24px;
        font-weight: 600;
        @include respond(600){
            font-size: 20px;
        }
    }
    &__subtitle{
        font-weight: 500;
        margin-bottom: 3rem;
        @include respond(600){
            font-size: 15px;
            margin-bottom: 2rem;
        }
    }
    &__list{
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 1rem;

        & img{
            width: 2.5rem;
           
        }
    }
}