@mixin respond($breakpoint){
    @if $breakpoint == 365{
        @media (max-width: 22.8em) {@content};
    }

    @if $breakpoint == 450{
        @media (max-width: 28em) {@content};
    }
  
    @if $breakpoint == 600{
        @media (max-width: 37.5em) {@content};
    }
  
    @if $breakpoint == 768{
        @media (max-width: 48em) {@content};  //768px
    }
  
    @if $breakpoint == 900{  //900px
        @media (max-width: 56.25em) {@content};  
    }

    @if $breakpoint == 1000{  //1000px
        @media (max-width: 62.5em) {@content};  
    }

    @if $breakpoint == 1065{  //1000px
        @media (max-width: 66.5em) {@content};  
    }

    @if $breakpoint == 1200{  //900px
        @media (max-width: 75em) {@content};  
    }

    @if $breakpoint == 1280{
        @media (max-width: 80em) {@content};  
    }
  
    @if $breakpoint == 1350{   //1350px
        @media (max-width: 84.37em) {@content};
    }

    @if $breakpoint == 1500{
        @media (min-width: 93.75em) {@content};
    }
  }

html{
  font-size: 62.5%;  // What 1rem is | 1rem = 10px;  10px/16px = 62.5%
  
  @include respond(1350){
      font-size: 56%;  // 1rem = 9px, 9/16 = 56%
  }
  
  @include respond(900){ // width < 900
      font-size: 50%; // 1rem = 8px, 8/16 = 50%
  }
}

