.c-pillar{
    display: flex;
    gap: 6rem;
    justify-content: center;

    @include respond(1200){
        flex-wrap: wrap; 
    }
    @include respond(600){
        gap: 3rem;
    }

    &__item{
        width: 15rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: relative;
        position: relative;

        

        &:not(:last-child)::before{
            content: url('../images/circle-wave.svg');
            width: 1rem;
            height: 1rem;
            position: absolute;
            z-index: -1;
            top: 1rem;
            right: 5rem;
            @include respond(1200){
               display: none; 
            }
        }
        
    }
    &__icon{
        width: 15rem;
        height: 15rem;
        background: var(--primary-color);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: 1px 1px 0px #24515e00;
        transition: all .2s;

        @include respond(600){
            width: 10rem;
            height: 10rem;
        }

        &:hover{
            background-color: white;
            box-shadow: 1px 1px 13px #24515e30;
            transform: scale(1.08);
        }

        & img{
            width: 8rem;
            @include respond(600){
                width: 5rem;
            }
        }
    }
    &__name{
        font-size: 18px;
        font-weight: 600;
        margin-top: 3rem;
        text-align: center;
        @include respond(600){
            font-size: 16px;
            margin-top: 2rem;
        }
    }

}