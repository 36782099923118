.section-investment{
    background-image: url('./../images/investment-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;

    &__content{
        padding: 13rem 10rem;
        padding-bottom: 8rem;
        background: linear-gradient(to right, #24515E 30%, rgba(36, 81, 94, 0.3882352941) 99%);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        @include respond(600){
            padding: 8rem 4rem;
            background: linear-gradient(to right, #24515E 54%, rgba(36, 81, 94, 0.3882352941) 175%);
          }
    }
    &__item{
        color: white;
        display: flex;
        gap: 5rem;
        font-size: 20px;
        margin-bottom: 5rem;
        max-width: 50rem;
        @include respond(600){
            margin-bottom: 2rem;
            font-size: 16px;
            gap: 2rem;
            align-items: center;
          }
    }
    &__icon img{
        
        @include respond(600){
            width: 4rem;
          }
    }
    &__text{
        @include respond(600){
            margin-bottom:0rem;
            
          }
    }
    & .c-btn--primary{
        margin-top: 3rem;
        margin-left: 10%;
    }
}


.section-funding{
    background-image: url('./../images/funding-bg.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 7rem 5rem;
    padding-bottom: 10rem;

    @include respond(450){
        padding: 5rem 2rem;
        padding-bottom: 5rem;
    }

    &__title{
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 7rem;
        @include respond(450){
            margin-bottom: 4rem;
        }
    }
    &__content{
        max-width: 80rem;
        display: flex;
        gap: 2rem;
        flex-wrap: wrap;
        @include respond(900){
            justify-content: center;
          }
    }
}


.section-banner{
    position: relative;
    background-image: url('./../images/banner-home-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 16rem 18rem;

    @include respond(900){
        padding:10rem;
      }

      @include respond(600){
        padding: 6rem;
      }
    &__title{
        color: white;
        font-size: 34px;
        max-width: 73rem;
        @include respond(900){
            font-size: 24px;
          }

          @include respond(600){
            font-size: 16px;
            line-height: 26px;
          }
        & b{
            font-size: 40px;
            @include respond(900){
                font-size: 28px;
              }
              @include respond(600){
                font-size: 18px;
              }
        }
    }
}

.section-goals{
    background: var(--primary-color);
    color: white;
    display: flex;
    overflow: hidden;
    padding-bottom: 4rem;
    background-image: url('../images/wave.svg');
    background-size: unset;
    background-repeat: no-repeat;
    background-position-y: -3rem;
    

    &__number{
        min-width: 40rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 9rem;
        padding-right: 3rem;
        & h3{
            font-size: 650px;
            line-height: 0px;
            font-weight: 600;
        }
        @include respond(900){
            min-width: 29rem;
          }
          @include respond(768){
            display: none;
          }
    }
    &__content{
        flex: 1;
        padding: 9rem 0 1rem 1rem;
        min-height: 60rem;
        max-width: 111rem;
        @include respond(768){
            padding: 5rem 5rem 1rem 5rem;
        }
        @include respond(450){
            padding: 5rem 3rem 1rem 3rem;
        }
    }
}


.section-news{

    &__header{
        display: flex;
        gap: 3rem;
        padding: 6rem 9rem;
        @include respond(600){
            padding: 6rem 3rem;
          }
          @include respond(450){
            padding: 4rem 3rem;
          }
    }
    &__body{
        padding: 0rem 0rem 3rem 9rem;
        @include respond(600){
            padding: 0rem 0rem 3rem 3rem;
          }
    }
    &__title{
        font-size: 36px;
        font-weight: 500;
        margin: 0;
        @include respond(450){
            margin-bottom: 0.5rem;
            font-size: 30px;
          }
    }
    &__subtitle{
        font-size: 17px;
        @include respond(450){
            font-size: 15px;
          }
    }
    &__footer{
        background: #537C88;
        padding: 30rem 10rem 5rem 10rem;
        margin-top: -27rem;
        display: flex;
        align-items: center;
        gap: 1rem;

        @include respond(768){
            flex-direction: column;
            padding: 28rem 2rem 4rem 10rem;
            align-items: flex-start;
          }
          @include respond(600){
            padding: 28rem 2rem 4rem 3rem;
          }
        & p{
            color: white;
            margin: 0;
            max-width: 24rem;
            margin-right: 3rem;
        }
    }
}