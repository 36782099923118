.l-memories{
    padding: 3rem 4rem;

    &__header{
        margin-bottom: 5rem;
        padding-left: 5rem;
        @include respond(1500){
            padding-left: 15rem;
        }
        @include respond(600){
            padding-left: 0rem;
        }
        @include respond(450){
            margin-bottom: 3rem;
        }
    }
    &__title{
        font-size: 30px;
        font-weight: 600;
        @include respond(450){
            font-size: 24px;
        }
    }
    &__subtitle{
        font-size: 16px;
        font-weight: 500;
       
    }
    &__body{
        margin-bottom: 5rem;
    }
}