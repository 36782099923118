.l-contact{

    &__header{
        padding: 2rem 10rem;
        background: var(--primary-color);
        color: white;
        font-size: 20px;
        font-weight: 500;

        @include respond(900){
            padding: 2rem 3rem;
        }
    }
    &__body{
        padding: 5rem 2rem;
        max-width: 120rem;
        margin: 0 auto;
        display: flex;
        gap: 2rem;
        padding-bottom: 7rem;
        @include respond(900){
            flex-direction: column-reverse;
            padding: 00;
            gap: 0;
        }
    }
    &__info{
        flex: 1;
        @include respond(900){
            padding: 5rem 3rem;
        }
    }
    &__title{
        font-size: 30px;
        font-weight: 600;
        margin-bottom: 2rem;
    }
    &__item{
        display: flex;
        align-items: center;
        gap: 1rem;
        color: var(--primary-color);
        font-weight: 600;
        margin-bottom: 2rem;

        & p{
            margin: 0;
        }
        & a{
            color: #3621D8;
            text-decoration: none;
            font-size: 14px;
        }
    }
    &__map{
        width: 45rem;
        @include respond(900){
            width: 100%;
            height: 20rem;
        }
        & iframe{
            width: 100%;
            height: 100%;
            border-radius: 1.5rem;
        }
    }
}