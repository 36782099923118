.l-faq{
    background-image: url('../images/faq-bg.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 64vw 15rem;

    &.no-bg{
        background-image: none;
    }
    &__header{
        background: var(--primary-color);
        padding: 2.5rem 0;
        @include respond(600){
            padding: 2.5rem 3rem;
        }
    }
    &__body{
        padding-top: 5rem;
        @include respond(450){
            padding-top: 3rem;
        }
    }
    &__title{
        font-size: 30px;
        color: white;
        margin: 0;
        font-weight: 500;
        @include respond(450){
            font-size: 24px;
        }
    }
}
