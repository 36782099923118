.l-hero-funds{
    // background-image: url('./../images/founds-energy.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 70vh;
    padding: 6rem;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    @include respond(450){
        padding: 2rem;
    }
    & > .row{
        max-width: 150rem;
    }
    &__title{
        font-size: 38px;
        color: white;
        font-weight: 600;
        margin-bottom: 3rem;
        @include respond(450){
            font-size: 30px;
         }
    }
    &__text{
        color: white;
        height: 38rem;
        overflow-y: auto;
        padding-left: 3rem;
        direction: rtl;

        @include respond(450){
            padding-left: 2rem;
            margin-bottom: 3rem;
         }

        & p{
            direction: ltr;
            margin-bottom: 2rem;
        }
    }
    &__image{
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        & img{
            width: 100%;
            max-width: 46rem;
            @include respond(450){
               width: 100%;
            }
        }
    }
    &__btn{
        position: absolute;
        bottom: 10%;
        left: 4.5%;
    }
    // &--building{
    //     background-image: url('./../images/funds-build.jpg');
    // }
    // &--society{
    //     background-image: url('./../images/funds-society.jpg');
    // }
    // &--capital{
    //     background-image: url('./../images/funds-capital.jpg');
    // }
    // &--liquidity{
    //     background-image: url('./../images/funds-liquidity.jpg');
    // }
}

.section-milestones{
    background: #F6F6F6;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    @include respond(365){
        padding: 4rem 2rem;
    }
    &__title{
        font-size: 29px;
        font-weight: 600;
        margin-bottom: 2rem;
    }
    &__body{
        max-width: 113rem;
    }
}

.section-tabs{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    @include respond(365){
        padding: 4rem 2rem;
    }
    &__title{
        font-weight: 500;
        color: #58585A;
        margin-bottom: 3rem;
        text-align: center;
    }
    &__content{
        display: flex;
        max-width: 107rem;
        gap: 1rem;
        flex-wrap: wrap;
        margin: 0 auto;
    
    }
}