.c-steps{
    display: flex;
    background: var(--primary-color);
    border-radius: 1rem;
    padding: 3rem 2rem 3rem 1rem;
    color: white;
    flex-wrap: wrap;
    max-width: 100rem;
    margin: 0 auto;
    justify-content: center;
    @include respond(450){
        padding: 1rem;
    }
    &__item{
        border-bottom: 1px solid #608089;
        padding: 2rem 5rem;
        max-width: 47rem;
        @include respond(1200){
            max-width: initial;
            width: 100%;
        }
        @include respond(450){
            padding: 2rem 2rem;
        }
        &:nth-child(1){
            border-right: 1px solid #608089;
            @include respond(1200){
                border-bottom: 1px solid #608089; 
                border-right: none;
            }
        }
        &:nth-child(2){
        }
        &:nth-child(3){
            border: 0;
            border-right: 1px solid #608089;
            @include respond(1200){
                border-bottom: 1px solid #608089; 
                border-right: none;
            }
        }
        &:nth-child(4){
            border: 0;
            @include respond(1200){
                border-bottom: 1px solid #608089; 
            }
            @include respond(1200){
                border: 0;
            }
        }
    }
    &__header{
        margin-bottom: 1rem;
        font-size: 24px;
        font-weight: 700;
    }
    &__text{
        font-size: 15px;
    }
    &__icons{
        display: flex;
        gap: 2rem;
        font-size: 11px;
        & span::before{
            color: var(--secondary-color);
        }
    }
   
}