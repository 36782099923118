*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
*, input, select, textarea, option, button {
  outline: none!important;
}

html{
    font-size: 62.5%;
    scroll-behavior: smooth;
}
body{
  font-size: 16px;
  font-family: 'Exo', sans-serif;
  color: var(--primary-color);
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #FFFFFF;
  border-radius: 5rem;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--secondary-color);
  border-radius: 5rem;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color);
}

@import url('https://fonts.googleapis.com/css2?family=Exo:wght@400;500;700&display=swap');

