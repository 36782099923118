.c-footer{
    background: var(--primary-color);
    color: #fff;
    padding: 6rem 10rem 3rem 10rem;

    @include respond(1500){
        padding: 6rem 20rem 3rem 20rem;
    }

    @include respond(1000){
        padding: 6rem 5rem 3rem 8rem;
      }
      @include respond(600){
        padding: 5rem 5rem 4rem 4rem;
      }
    

    &__title{
        font-size: 30px;
        font-weight: 600;
        line-height: 36px;
        margin-bottom: 4rem;
        max-width: 28rem;
        
        @include respond(450){
            margin-bottom: 1rem;
            font-size: 22px;
        }
        
    }
    &__copy{
        text-align: center;
        font-size: 12px;
        margin-top: 4rem;
    }
    &__list{
        margin: 0;
        padding: 0;

        & li{
            list-style-type: none;
            margin-bottom: 2rem;
            @include respond(450){
                margin-bottom: 1rem;
              }
        }
        & a{
            color: white;
            text-decoration: none;

            &:hover{
                color: var(--secondary-color);
            }
        }
    }
    &__social{
        font-size: 29px;

        & span{
            cursor: pointer;
        }
        & span:hover::before{
            color: var(--secondary-color);
        }
    }
    &__logos{
        margin: 4px;
    }
}