.c-accordion{
    @include respond(450){
        padding: 0 1rem;
    }
    & .accordion-item{
        border: 0;
    }
    & .accordion-button{
        background: white;
        padding: 2rem 1rem;
        font-size: 24px;
        color: var(--secondary-color);
        border-bottom: 1px solid #dee2e6;
        box-shadow: none;
        display: flex;
        font-weight: 400;
        flex-wrap: wrap;
        @include respond(450){
            font-size: 20px;
        }
        & span{
            margin-right: 1rem;
            font-weight: 600;
        }
    }
    & .accordion-body{
        padding: 2rem 0;
        border-bottom: 1px solid #dee2e6;
    }

    & .c-faq-item{
        @include respond(450){
            padding: 0rem;
        }
    }
}


.c-accordion-document{
    width: 70rem;
    margin-top: 2rem;
    @include respond(768){
        width: 90vw;
    }
    
    & .accordion-button{
        font-weight: 600;
        font-size: 16px;
        padding: 2rem 4rem;
        background: var(--primary-color);
        color: white;
        margin-bottom: 0.5rem;
        border-radius: 0;
        flex-wrap: wrap;
        

        &::after{
            margin-left: 2rem;
            content: "+";
            background-image: none;
            transform: none !important;
            height: 3rem;
            font-size: 3rem;
            line-height: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &:not(.collapsed)::after{
            content: "-";
        }
    }
    & .accordion-body{
        margin-bottom: 0.5rem;
        padding: 0;
    }
    & .accordion-item {
        border: none;
    }
    &__item{
        background: #58585A;
        color: white;
        display: flex;
        padding: 1.5rem 4rem;
        gap: 1rem;
        align-items: center;
        justify-content: space-between;
        font-size: 13px;
        font-weight: 600;
        margin-bottom: 3px;
        flex-wrap: wrap;

    }
    &__button{
        background: white;
        color: var(--primary-color);
        border: 0;
        border-radius: 5px;
        padding: 0.9rem 4rem;
        font-weight: 700;
        transition: all .2s;
        text-decoration: none;

        &:hover{
            background-color: var(--secondary-color);
            color: white;
        }
        &:active{
            transform: scale(.95);
        }
    }

    &__archive{
        font-weight: 400;
    font-size: 13px;
    }
}

.c-accordion-how{
    & .accordion-item{
        border: 0;
        margin-bottom: 2rem;
    }
    & .accordion-header{
        margin-bottom: 0;
    }
    & .accordion-body{
        padding: 4rem;
        @include respond(450){
            padding: 3rem;
        }
    }
    & .accordion-button{
        background: var(--primary-color);
        border-radius: 10px !important;
        border: 0;
        display: flex;
        align-items: center;
        height: 9.5rem;
        color: white;
        gap: 3rem;
        padding: 0 4rem 0rem 2rem;
        @include respond(768){
            padding: 0 2rem 0rem 2rem;
            gap: 2rem;
        }
        &:after{
            display: none;
        }
    }
        
    &__title{
        font-weight: 700;
        margin-bottom: 2px;
        font-size: var(--big-size-2);
        @include respond(768){
            margin-bottom: 5px;
            font-size: 16px;
        }
        @include respond(365){
            font-size: 12px;
        }
    }
    &__subtitle{
        margin: 0;
        font-size: var(--normal-size);
        @include respond(768){
            font-size: 13px;
            line-height: 16px;
        }
         @include respond(365){
           font-size: 10px;
            line-height: 14px;
        }
    }
    &__number{
        margin-left: auto;
        font-size: 27px;
        font-weight: 700;
        @include respond(768){
            font-size: 2rem;
            text-align: center;
        }
        @include respond(365){
            font-size: 1.4rem;
         }
    }
    &__icon{
        flex: 0 0 6rem;
        height: 6rem;
        display: flex;
        align-items: center;
        justify-content: center;
        background: white;
        border-radius: 50%;
        
        @include respond(450){
            flex: 0 0 5rem;
            height: 5rem;
        }
        @include respond(365){
            flex: 0 0 4rem;
            height: 4rem;
        }
        & img{
            width: 2.4rem;
            height: 100%;
            @include respond(450){
                width: 2rem;
            }
        }
        
    }
}