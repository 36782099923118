.c-slider-news{

  /*   margin: 1rem 10rem 5rem;
    @include respond(600){
        margin: 1rem 2rem 5rem;
    }
     */
   
}


.c-slider-doc{
    margin-bottom: 10rem;
    max-width: 100rem;
    width: 100%;
    margin: 0 auto;

    & .slick-prev{
        right: initial;
        left: 1rem;
        top: 16rem;
    }
    & .slick-next{
        top: 16rem;
        right: 1rem;
    }
    & .slick-prev, 
    & .slick-next{
        border-radius: 50%;
    }
    &__item{
        max-width: 26rem;
    }
    &__document{
        height: 33rem;
        background: linear-gradient(#7ab53f 0%, #24515e 100%);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        color: white;
        padding: 3rem 2rem 2rem 2rem;
        position: relative;
    }
    &__title{
        font-weight: 600;
        text-transform: uppercase;
    }
    &__subtitle{
        font-size: 11px;
    }
    &__number{
        margin-top: auto;
        align-self: flex-end;
        font-size: 90px;
        line-height: 66px;
        font-weight: 700;
        opacity: .7;
        font-family: sans-serif;
        @include respond(768){
            font-size: 55px;
            line-height: 40px;
        }
    }
    &__icon{
        position: absolute;
        bottom: 2rem;
        left: 2rem;
    }
    &__name{
        text-align: center;
        margin-top: 2rem;
        margin-bottom: 0.5rem;
        font-weight: 600;
        opacity: 0;
    }
    &__button{
        border: 0;
        padding: 1rem 2rem;
        font-weight: 600;
        color: #666666;
        width: 100%;
        transition: all .2s;
        border-radius: 5px;
        text-decoration: none;
        display: block;
        text-align: center;
        background: #F0F0F0;
        opacity: 0;

        &:hover{
            background-color: var(--secondary-color);
            color: white;
        }
        &:active{
            transform: scale(.95);
        }
    }

    .slick-slide {
        opacity: 0.5;
        transform: scale(0.9);
    }
    .slick-slide.slick-current.slick-active.slick-center {
        opacity: 1;
        transform: initial;

        & .c-slider-doc__name,
        & .c-slider-doc__button{
            opacity: 1;
        }
    }
}


/* Slider */

.slick-slider {
    gap: 2rem;
    position: relative;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    flex: 1;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    gap: 1rem;
    align-items: center;

    &:before,
    &:after {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}
.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
        float: right;
    }
    img {
        display: block;
    }
    &.slick-loading img {
        display: none;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}
.slick-arrow.slick-hidden {
    display: none;
}


// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: "../fonts/" !default;
$slick-font-family: "slick" !default;
$slick-loader-path: "./" !default;
$slick-arrow-color: white !default;
$slick-dot-color: var(--primary-color) !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: "\2190" !default;
$slick-next-character: "\2192" !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 30px !default;
$slick-opacity-default: 0.75 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: 0.25 !default;

@function slick-image-url($url) {
    @if function-exists(image-url) {
        @return image-url($url);
    }
    @else {
        @return url($slick-loader-path + $url);
    }
}

@function slick-font-url($url) {
    @if function-exists(font-url) {
        @return font-url($url);
    }
    @else {
        @return url($slick-font-path + $url);
    }
}

/* Slider */

/* Icons */

/* Arrows */

.slick-prev,
.slick-next {
    position: initial;
    font-size: 0px;
    cursor: pointer;
    transform: translate(0, -50%);
    color: #fff;
    cursor: pointer;
    height: 4rem;
    width: 4rem;
    border: 0;
    background: #788A98;
    transition: all .2s;
    border-radius: 5px;

    &:hover{
        background: var(--secondary-color);
    }

    &.slick-disabled:before {
        opacity: $slick-opacity-not-active;
    }
    &:before {
        font-size: 20px;
        line-height: 1;
        color: $slick-arrow-color;
        opacity: $slick-opacity-default;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
}

.slick-next {
    position: absolute;
    right: 4rem;
}

.slick-prev{
    right: 4rem;
    position: absolute;
    top: 15rem;
    z-index: 1;
}

.slick-prev {
    [dir="rtl"] & {
        left: auto;
        right: -25px;
    }
    &:before {
        content: $slick-prev-character;
        [dir="rtl"] & {
            content: $slick-next-character;
        }
    }
}

.slick-next {
    [dir="rtl"] & {
        left: -25px;
        right: auto;
    }
    &:before {
        content: $slick-next-character;
        [dir="rtl"] & {
            content: $slick-prev-character;
        }
    }
}

/* Dots */

.slick-dotted.slick-slider {
    margin-bottom: 30px;
}

.slick-dots {
    position: absolute;
    bottom: -5rem;
    list-style: none;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 100%;
    display: none !important;
    li {
        position: relative;
        display: inline-block;
        height: 20px;
        width: 20px;
        margin: 0 5px;
        padding: 0;
        cursor: pointer;
        button {
            border: 0;
            background: transparent;
            display: block;
            height: 20px;
            width: 20px;
            outline: none;
            line-height: 0px;
            font-size: 0px;
            color: transparent;
            padding: 5px;
            cursor: pointer;
            &:hover, &:focus {
                outline: none;
                &:before {
                    opacity: $slick-opacity-on-hover;
                }
            }
            &:before {
                position: absolute;
                top: 0;
                left: 0;
                content: $slick-dot-character;
                width: 20px;
                height: 20px;
                
                font-size: $slick-dot-size;
                line-height: 20px;
                text-align: center;
                color: $slick-dot-color;
                opacity: $slick-opacity-not-active;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
        &.slick-active button:before {
            color: $slick-dot-color-active;
            opacity: $slick-opacity-default;
        }
    }
}