.c-form-search{
    position: relative;

    &__input{
        padding: 0.8rem 2rem;
        border: 1px solid var(--primary-color);
        border-radius: 5px;
        color: #58585A;
        font-size: 14px;
        &:focus{
            border: 1px solid var(--secondary-color);
        }
    }
    &__icon{
        position: absolute;
        top: 1rem;
        right: 1rem;
    }
}

.c-form-pagination {
    margin: 0;

    & .page-link{
        font-size: 16px;
        color: #4D4F5C !important;
        padding: 0.9rem 2rem;
    }
    & .page-item.active .page-link{
        background-color: var(--primary-color);
        color: white !important;
        border-color: var(--primary-color);
    }
}

.c-form-group{
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    &__label{
        font-size: 15px;
        font-weight: 600;
    }
    &__input{
        border: 0;
        background: transparent;
        padding: 0.3rem 1rem 1rem 0rem;
        color: white;
        border-bottom: 2px solid white;
        font-size: 14px;

        & option{
            color: var(--primary-color);
        }

        &::placeholder{
            color: white;
        }

    }
    &__textarea{
        border: 1px solid var(--primary-color);
        border-radius: 5px;
        padding: 2rem;
        margin-top: 1rem;
        color: var(--primary-color);
        font-size: 14px;
    }
    &--dark{
        & .c-form-group__input{
            color: var(--primary-color);
            border-bottom: 2px solid var(--primary-color);

            &::placeholder{
                color: var(--primary-color);
            }
        }
    }
}


.c-form-work{
    border: 1px solid white;
    border-radius: 2rem;
    padding: 4rem; 
    color:white;

    &__title{
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 3rem;
    }
    & .row{
        & .col-lg-4{
            padding-right: 4rem;
            border-right: 1px solid #ffffff3b;

            @include respond(1000){
                padding: 0;
                border-right: 0;
            }
        }
        & .col-lg-5{
            border-right: 1px solid #ffffff3b;
            padding-left: 4rem;
            padding-right: 5rem;
            @include respond(1000){
                padding: 0;
                border-right: 0;
            }
        }
    }
}

.c-form-file{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0rem 3rem;
    &__input{
        position: relative;
        width: 10rem;
        height: 10rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        border-radius: 5px;
        border: 2px dashed var(--secondary-color);
        font-size: 30px;
        margin: 2rem 0rem 4rem 0rem;
        cursor: pointer;

        &:hover{
            background-color: var(--secondary-color);
            border-color: white;
            & span::before{
                color: white;
            }
        }

        & input{
            position: absolute;
            opacity: 0;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            cursor: pointer;
        }
    }
    &__title{
        font-size: 15px;
        font-weight: 600;
        text-align: center;
    }
    &__subtitle{
        font-size: 12px;
        margin-bottom: 2rem;
        text-align: center;
    }
}