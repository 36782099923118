.l-hero-about{
    padding-top: 11rem;
    min-height: 100vh;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @include respond(450){
        padding-top: 0rem;
        align-items: center;

     }

    &__content{
        background: linear-gradient(to top, #24515E 15%, rgb(36 81 94 / 0%) 101%);
        color: white;
        padding: 19rem 10rem 5rem 10rem;
        min-height: 80vh;
        display: flex;
        align-items: flex-end;
        @include respond(768){
            padding: 19rem 4rem 5rem 4rem;
        }
        @include respond(450){
            padding: 11rem 3rem 5rem 3rem;
            background: linear-gradient(to top, #24515E 18%, rgba(36, 81, 94, 0) 100%);
         }
    }
    &__title{
        font-size: 39px;
        margin-bottom: 2rem;
        font-weight: 500;
    }
    &__text{
        height: 15rem;
        overflow: hidden;
        overflow-y: auto;
        padding-left: 3rem;
        direction: rtl;

        @include respond(450){
            height: 35rem;
            padding-left: 2rem;
         }

        & p{
            direction: ltr;
            margin-bottom: 2rem;
        }
    }
    &__cards{
        display: flex;
        gap: 1rem;
        justify-content: center;
        @include respond(450){
           flex-direction: column;
           margin-bottom: 3rem;
        }
    }
}


.l-about{
    
    &__header{
        display: flex;
        align-items: center;
        gap: 2rem;
        padding: 2rem 10rem;
        background: #F0F0F0;

        @include respond(1500){
            padding: 2rem 20rem;
        }
        @include respond(600){
            padding: 2rem 3rem;
        }

        
    }
    &__title{
        font-size: 22px;
        font-weight: 600;
        margin: 0;

        & span{
            font-weight: 400;
        }
    }
    &__body{
        padding: 5rem 10rem;
        @include respond(1200){
            padding: 5rem 3rem;
        }

        &--bg{
           background-image: url('../images/profile-bg.svg'); 
           background-size: 100rem;
           background-repeat: no-repeat;
           background-position: center 3rem;
        }
        &.respond{
            @include respond(1500){
                padding-left: 20rem;
            }
        }
        @include respond(1200){
            flex-wrap: wrap; 
        }
    }
}