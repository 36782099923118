:root{
    --primary-color: #24515E;
    --secondary-color: #7AB53F;
    --secondary-color-darker: #6AA430;
    --tertiary-color: #303640;
    --tertiary-color-light: #636A75;
    --white-color: #F6F6F6;


    --normal-size: 16px;
    --small-size: 14px;
    --small-size-2: 12px;
    --big-size: 18px;
    --big-size-2: 20px;
    --big-size-3: 28px;


    --radius: 0.9rem;
}
