.c-profile{
    display: flex;
    flex-wrap: wrap;
    gap: 5rem;
    max-width: 103rem;
    margin: 0 auto;

    @include respond(1200){
        justify-content: center;
    }
    &__item{
        width: 22rem;
    }

    &__image{
        position: relative;
        cursor: pointer;
        & > img{
            width: 100%;
            object-fit: cover;
            border-radius: 1.5rem;
        }
    }
    &__icon{
        position: absolute;
        width: 4.5rem;
        height: 4.5rem;
        background: white;
        border: 1px solid #DFDFDF;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        bottom: -1rem;
        right: -1.5rem;
        cursor: pointer;
        transition: all .2s;

        &:hover{
            background-color: var(--secondary-color);
            border: 1px solid var(--secondary-color);
        }
    }
    &__name{
        text-align: center;
        font-weight: bold;
        margin: 1.5rem 0 0.5rem 0;
        padding: 0 1rem;
    }
    &__role{
        text-align: center;
        font-size: 14px;
        padding: 0 1rem;
    }
}