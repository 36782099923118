.l-hero{
    &__content{
        max-width: 65rem;
        text-align: left;
        position: absolute;
        color: white;
        top: 17rem;
        left: 8%;
        @include respond(450){
            top: 13rem;
            left: 0;
            margin: 2rem;
            width: calc(100% - 4rem);
          }
        @include respond(1500){
            top: 25rem;
            left: 11%;
        }

        .d-flex.u-gap-1{
            @include respond(450){
                flex-direction: column;
              }
        }
    }
    &__title{
        font-size: 48px;
        margin-bottom: 10rem;
        font-weight: 400;
        @include respond(900){
            font-size: 30px;
            margin-bottom: 5rem;
            max-width: 80%;
         }
         @include respond(450){
            max-width: 100%;
            line-height: 40px;
          }
    }
}