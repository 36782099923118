.l-work{
    min-height: 100vh;
    position: relative;
    padding: 12rem 11rem 7rem 11rem;
    background-image: url("../images/work-bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    @include respond(600){
        padding: 12rem 2rem 6rem 2rem;
    }
    
    &__header{
        color: white;
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 3rem;

        & .c-btn{
            @include respond(450){
                width: 100%;
            }
        }


    @include respond(600){
        margin-bottom: 2rem;
        gap: 0;
    }
    }
    &__title{
        font-size: 34px;
        font-weight: 500;
        @include respond(600){
            font-size: 28px;
        }
    }
}