.c-stepper{

    &__header{
        margin-bottom: 1rem;
    }
    &__title{
        font-size: 13px;
        font-weight: 600;
    }
    &__subtitle{
        font-size: 13px;
    }
    & .nav-item {
        flex: 1;
        & .nav-link{
            margin-right: 0 !important;
            border: 0 !important;
            padding: 0.8rem 1rem !important;
            font-size: 13px;
            text-align: center;
            width: 100%;
            background: #C7C7C7;
            color: #9E9E9E !important;
            height: 100%;

            & span::before{
                color: #A4A4A4;
                transform: rotate(177deg);
                display: inline-block;
                font-size: 9px;
                position: relative;
                top: -1px;
                margin-left: 10px;

            }

            &.active{
                background-color: var(--primary-color);
                color: white !important;

                & span::before{
                    color: var(--secondary-color);
    
                }
            }
        }
    }
    &__button{
        border: 1px solid var(--primary-color);
        border-radius: 10rem;
        padding: 0.5rem 4rem;
        font-size: 13px;
        font-weight: 600;
        color: var(--primary-color);
        transition: all .2s;
        &:hover{
            background-color: var(--primary-color);
            color: white;
        }
        &.active{
            background-color: var(--primary-color);
            color: white;
        }
    }
   
}